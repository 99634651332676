/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import React, { useRef, useEffect, useState } from 'react'
import { Button, TextField, Typography, List, LinearProgress } from '@mui/material'
import * as XLSX from 'xlsx'
import axios from 'axios'

const Form = ({endpoint}) => {
  const token = localStorage.getItem("token")

  const fileInputRef = useRef()
  const [resultMessages, setResultMessages] = useState([])
  const [rowStatus, setRowStatus] = useState({total:0, count:0})
  const [rowPercent, setRowPercent] = useState(0)
  const [uploadInProgress, setUploadInProgress] = useState(false)
  const [successfulRows, setSuccessfulRows] = useState(0)
  const [eventId, setEventId] = useState("")
  console.log('eventId=',eventId)

  const excelDateToJSDate = (date) => {
    let convertedDate = XLSX.SSF.parse_date_code(date)
    let formattedDate = `${convertedDate.y}-${convertedDate.m}-${convertedDate.d}`
    console.log("Formatted Date", formattedDate)
    return formattedDate
  }

  const excelTimeToJSTime = (time) => {
    console.log("Time", time)
    const convertedTime = XLSX.SSF.parse_date_code(time)
    console.log("convertedTime", convertedTime)
    let hour = convertedTime.H.toString()
    let minute = convertedTime.M.toString()
    hour = hour.length === 1 ? '0' + hour : hour
    minute = minute.length === 1 ? '0' + minute : minute
    const formattedTime = `T${hour}:${minute}`
    console.log("Formatted Time", formattedTime)
    return formattedTime
  }

  const mapRowToFunction = async (row, header) => {
    console.log("row type", typeof row) // row type is object 
    console.log('eventId=',eventId)
    console.log("Each row", row)
    row['startDate'] = excelDateToJSDate(row[6]) + 'T00:00'
    row['endDate'] = excelDateToJSDate(row[8]) + 'T00:00'
    row['startTime'] = excelDateToJSDate(row[6]) + excelTimeToJSTime(row[7])
    row['endTime'] = excelDateToJSDate(row[8]) + excelTimeToJSTime(row[9])
    console.log(`!!!! FINAL startTime=${row['startTime']}  endTime=${row['endTime']}`)
    return {
        "eventId": eventId,
        "usage": row[2],
        "functionName": row[3],
        "location": row[4], 
        "space": row[5],
        "startDate": row['startDate'],
        "startTime": row['startTime'],
        "endDate": row['endDate'],
        "endTime": row['endTime'],
        "SignageZone": row[10] || "No",
        "SignageRoom": row[11] || "No",
        "ProgramAtAGlance": row[12] || "No"
    }
  }

  const insertFunction = async (functionData) => {
    try { 
      let url = `${endpoint}/functions/insert`
      console.log("Pre axios request")
      let response = await axios.post(
        url, 
        functionData, 
        {"headers":{
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        }}
      )
      console.log("API Response:", response)
      return {status: response.status, text: response.statusText}
    }
    catch (error) {
      console.log('\n------ insertEventFunction error ------\n', error)
    }
  }

  const convertBlob = async (file) => {
    try {
      let data = await file.arrayBuffer()
      return data
    } catch (error) {
      console.log('error=', error)
    }
  }

  const readSpreadsheet = async (file) => {
    let data = await convertBlob(file)
    const workbook = XLSX.read(data, {type: 'buffer'})
    const worksheetName = workbook.SheetNames[0]
    const worksheet = workbook.Sheets[worksheetName]
    console.log("worksheet", worksheet)
    let rows = XLSX.utils.sheet_to_json(worksheet, { header: 1, range: 1, defval: '', blankrows: false })
    const header = rows[1]
    rows = rows.slice(3)
    return {rows: rows, header: header}
  }

  const requiredColumns = ['Usage', 'Description', 'Start Date', 'Start Time', 'End Date', 'End Time']
  const validateRequiredFields = (row, header) => {
    let missingFieldErrors = []
    for (const column of requiredColumns) {
      if (header.includes(column)) {
        const fieldValue = row[header.indexOf(column)]
        missingFieldErrors = !fieldValue ? [...missingFieldErrors, `${column}`] : missingFieldErrors
      }
    }
    console.log("missing Field Errors", missingFieldErrors)
    return missingFieldErrors
  }

  const handleSubmit = async ({ target }) => {
    console.log('*** target=',target)

    setUploadInProgress(true)
    const {rows, header} = await readSpreadsheet(target.files[0])
    let rowCount = 1
    let rowMessages = []
    let rowStatus = {}
    let successfullyLoadedRows = 0
    const rowTotal = rows.length
    for (const row of rows) {
      setRowPercent((rowCount * 100) / rowTotal)
      console.log('B --------------------------------------------------')
      console.log(`row count=${rowCount}   row=${row}`)
      let functionData = await mapRowToFunction(row, header)
      console.log(validateRequiredFields(row, header))
      const missingFieldErrors = validateRequiredFields(row, header)
      console.log(`row=${row}  missingFieldErrors=${missingFieldErrors}`)
      let result = missingFieldErrors.length === 0 ? await insertFunction(functionData) : {'status': 'error', 'text': `Row is missing fields in the following columns: ${missingFieldErrors.join(', ')}.`}
      //let result = missingFieldErrors.length === 0 ? {'status': 200} : {'status': 'error', 'text': `Row is missing fields in the following columns: ${missingFieldErrors.join(', ')}.`}
      console.log('result=',result)
      console.log('handleSubmit rowMessages=',rowMessages)
      if (result.status === 200) {
        rowStatus = {total: rowTotal, count: rowCount}
        successfullyLoadedRows += 1
      } else {
        rowMessages = [...rowMessages,`row: ${rowCount} - ${result.status} - ${result.text}`]
      } 
      rowCount += 1
      console.log('E --------------------------------------------------')
      setRowStatus(rowStatus)
    }
    setResultMessages(rowMessages)
    setSuccessfulRows(successfullyLoadedRows)
  }

  const handleCancel = async () => {
    console.log('** handleCancel')
    setUploadInProgress(false)
    setRowPercent(0)
    setEventId(null)
    setResultMessages([])
    setRowStatus({total:0, count:0})
    window.location.reload(false)
  }

  useEffect(() => {
  }, [])

  return(
    <div className='form'>
      <Typography variant="h4" css={css`padding: 20px 0px`}> 
        Upload Functions to Momentus
      </Typography>
      <TextField 
        id="eventId" 
        label="Event ID"
        onChange={(v) => setEventId(v.target.value)} 
        type="number"
        value={eventId}
      /> 

      <div className='attach-document'>
        <Button
          content='Choose File'
          variant='contained'
          icon='file'
          onClick={() => fileInputRef.current.click()}
          data-testid='uploadButton'
          disabled={eventId ? false : true}
          disableElevation
        >
          Select and Upload
        </Button>
        <input
          ref={fileInputRef}
          data-testid='fileUpload'
          type='file'
          hidden
          onChange={handleSubmit}
        />
      </div><br/><br/>
      <Typography variant="h5" > 
        Progress: {Math.round(rowPercent)}%
      </Typography>
      <LinearProgress variant='determinate' value={rowPercent} style={{height: '20px', paddingBottom: '0', maxWidth: '70%'}} />
      <Typography variant="subtitle1"> 
        {successfulRows} / {rowStatus.total} successfully loaded.
      </Typography>
      <br/>
      <Button
          variant='contained'
          disabled={!uploadInProgress}
          onClick={handleCancel}
        >
          Cancel / Reset
        </Button>
      <div style={{height: '20%',overflowY: 'scroll'}}>
        {resultMessages.map(msg => <List>{msg}</List>)}
      </div>
    </div>
  )
  
}

export default Form