/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react"
import React from 'react'
import { useState, useEffect } from "react"
import { NavLink, Link } from 'react-router-dom'
import { Typography } from '@mui/material'

const navContainer = css`
  margin: 16px auto;
  display: flex;
  flex-direction: column;
  height: 100%;
`

const typography = css`
  color: white;
`

const footer = css`
  background: white;
  margin-top: auto;
  margin-left: -40px;
  padding: 16px 0px 30px 40px;
`

const helps = {
  "localhost": "https://s3.amazonaws.com/dev.cc.igeno.com/cc-help.pdf",
  "dev.cc.igeno.com": "https://s3.amazonaws.com/dev.cc.igeno.com/cc-help.pdf",
  "test.cc.igeno.com" : "https://s3.amazonaws.com/test.cc.igeno.com/cc-help.pdf",
  "cc.igeno.com": "https://s3.amazonaws.com/cc.igeno.com/cc-help.pdf"
}

const removePassword = () => {
  localStorage.removeItem('token')
  window.location.href = window.origin
}

function SideBar(props) {
  const token = localStorage.getItem("token")

  const [helpEndpoint, setHelpEndpoint] = useState('')

  useEffect(() => {
    const hostname = window.location.hostname
    const endpointValue = helps[hostname] || ''

    setHelpEndpoint(endpointValue)
  },[])

  return (
    <nav id="sidebar">
      <ul css={navContainer}>
        {
          token ?
          // window.location.href === window.origin + '/uploadForm' ?
          <li>
            <NavLink to='/' activeclassname="selected">
              <Typography variant="h6" gutterBottom css={typography} onClick={() => removePassword() }>
                LOGOUT
              </Typography>
            </NavLink>
          </li>
        :
          <li>
            <NavLink to='/login' activeclassname="selected">
              <Typography variant="h6" gutterBottom css={typography}>
                LOGIN
              </Typography>
            </NavLink>
          </li>
        }
        {
          token && 
          <li>
            <NavLink to='/uploadForm' activeclassname="selected">
              <Typography variant="h6" gutterBottom css={typography}>
                Upload
              </Typography>
            </NavLink>
          </li>
        }
        <li>
          <Link to={helpEndpoint} activeclassname="selected" target="_blank">
            <Typography variant="h6" gutterBottom css={typography}>
              Help/Tutorial
            </Typography>
          </Link>
        </li>
        <li css={footer}>
          <NavLink to='/' activeclassname="selected" >
            <img src="/logo-logo-cc.svg" alt="Igeno logo" />
          </NavLink>
        </li>
      </ul>
    </nav>
  )
}

export default SideBar