import React, { useRef, useEffect, useState } from 'react'
import { Button, TextField, Typography } from '@mui/material'
import { withRouter } from 'react-router-dom'
import axios from 'axios'



const Login = ({ endpoint }) => {
/*  
  constructor(props) {
    super(props);
    this.state = {
      invalidField: false,
      disabled: false
    }
  }
*/

  // checkFields = () => {
  //   if (document.getElementById("username").value === "" || document.getElementById("password").value === "") {
  //     this.setState({ invalidField: true})
  //   } else {
  //     this.setState({ invalidField: false })
  //   }
  // }

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const handleSubmit = async (event) => {
    event.preventDefault()

    // props.history.push('/uploadForm') 

    let body = {
      "email": email,
      "password": password
    }

    let options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      mode: "cors",
      credentials: "same-origin"
    }

    try {
      const response = await axios.post(`${endpoint}/users/login`, body, options)
      console.log("response data", response.data)
      localStorage.setItem('token', response.data.token)
      window.location.href = '/uploadForm'
    } catch (error) {
      console.log("Errors", error)
    }
  }

  return(
    <div className='form'>
      <Typography variant="h4" >
        Login
      </Typography>
      <form  autoComplete="off">
        <div className="input-fields">
          <TextField id="email" label="Email" margin="normal" type="email" autoComplete='email' onChange={(e) => setEmail(e.target.value)}/>
          <br/>
          <TextField id="password" label="Password"  margin="normal" type="password" autoComplete='current-password' onChange={(e) => setPassword(e.target.value)}/>
        </div>
        <br/>
        <div className='submit'>
          <Button variant="contained" id="submit-button"  onClick={handleSubmit} disableElevation>
            Submit
          </Button>
        </div>
      </form>
      <br/>
    </div>
  )
}

export default Login
